import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
	AppBar,
	Box,
	Button,
	Container,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListSubheader,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import nexusLogo from "./images/nexus_logo_ddd.png";
import backpack1 from "./images/backpack1.jpeg";
import backpack2 from "./images/backpack2.jpeg";
import backpack3 from "./images/backpack3.jpeg";

const App = () => {
	const [selection, setSelection] = useState("bowling");

	return (
		<div className="App">
			<AppBar position="fixed" sx={{ backgroundColor: "#000052" }}>
				<Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
					Nexus Bag
				</Typography>
				<Box>
					<Button
						onClick={() => setSelection("bowling")}
						sx={{ color: "white" }}
					>
						Bowling
					</Button>
					<Button
						onClick={() => setSelection("travel")}
						sx={{ color: "white" }}
					>
						Travel
					</Button>
					<Button sx={{ color: "white" }}>Buy Now</Button>
				</Box>
			</AppBar>
			<Box
				name="section0"
				sx={{
					backgroundImage:
						selection === "bowling"
							? "linear-gradient(#000, #333)"
							: "linear-gradient(#001c08, #00330f)",
					color: "#ddd",
					height: "100%",
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<img
						src={nexusLogo}
						alt="Nexus Logo"
						style={{ width: "100%" }}
					/>
					{selection === "bowling" ? (
						<Typography variant="h6" sx={{ marginTop: 4 }}>
							A specially made backpack for all your bowling and
							tournament needs, the Nexus Bag keeps you organized
							and is durable enough for wherever your bowling
							takes you.
						</Typography>
					) : (
						<Typography variant="h6" sx={{ marginTop: 4 }}>
							A specially made backpack for all your travel needs,
							the Nexus Bag keeps you organized and is durable
							enough for all your urban and rural excursions.
						</Typography>
					)}
					<Typography sx={{ marginTop: 5 }}>
						Want to compare information about the Nexus Bag for
						travel and bowling? Use the buttons on the bar above to
						switch between "bowling" and "travel" details!
					</Typography>
				</Container>
			</Box>

			<Box
				name="section1"
				sx={{
					backgroundImage:
						selection === "bowling"
							? "linear-gradient(#333, #000)"
							: "linear-gradient(#00330f, #001c08)",
					color: "#ddd",
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5" sx={{ marginBottom: 2 }}>
						The Nexus Bag
					</Typography>
					<img
						src={backpack2}
						alt="backpack 2"
						style={{ maxWidth: "100%", maxHeight: 400 }}
					/>
					{selection === "bowling" ? (
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							For bowlers that want to improve their craft or the
							pros bowling for years, the Nexus Bag has you
							covered for all your events. This veteran owned,
							U.S. designed backpack has room for all your
							equipment and accessories, making the Nexus Bag the
							only bag specially equipped for both league and
							tournaments!
						</Typography>
					) : (
						<Typography variant="body1">
							Traveling or hiking overnight and need more than
							your standard backpack? Need more organization space
							for your trip? Tired of sticking your extra pair of
							shoes in the bottom of your bag? The Nexus Bag has
							you covered! With space for your laptop, shoes,
							accessories and organizational space for all your
							small items, the Nexus Bag excels at short trips
							through rugged or office terrain!
						</Typography>
					)}
				</Container>
			</Box>
			<Box
				name="section2"
				sx={{
					backgroundImage:
						selection === "bowling"
							? "linear-gradient(#000, #333)"
							: "linear-gradient(#001c08, #00330f)",
					color: "#ddd",
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5">Features</Typography>
					{selection === "bowling" ? (
						<Grid container>
							<Grid item xs={12} sm={6}>
								<List dense>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Ventilated Shoe Compartment"
											secondary="Fits up to size 14!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Tons of storage"
											secondary="Lots of pockets for organization."
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Laptop section"
											secondary='Space for a 16" laptop!'
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Removable accessory kits"
											secondary="Take only what you need to the lane."
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="USB Battery Adapter"
											secondary="Plug your battery in on the inside and use the external USB port to power your devices!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
								</List>
							</Grid>
							<Grid item xs={12} sm={6}>
								<List dense>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Military Grade rucksack design"
											secondary="500 count codoura fabric, heavy duty zippers"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Modular accessory kit"
											secondary="Sort everything for your needs"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Surface and Cleaning Kit"
											secondary='Waterprooof compartment for cleaners, fits 6" sanding pads'
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Water Bottle Holder"
											secondary="Attach your standard neck water bottles right to the bag!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Customize with patches!"
											secondary="Velcro patches available in the store!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
								</List>
							</Grid>
						</Grid>
					) : (
						<Grid container>
							<Grid item xs={12} sm={6}>
								<List dense>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Ventilated Shoe Compartment"
											secondary="Fits up to size 14!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Tons of storage"
											secondary="Lots of pockets for organization."
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Laptop section"
											secondary='Space for a 16" laptop!'
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Removable accessory kits"
											secondary="Take them off to reduce size or open them on the table to access everything easily"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="USB Battery Adapter"
											secondary="Plug your battery in on the inside and use the external USB port to power your devices!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
								</List>
							</Grid>
							<Grid item xs={12} sm={6}>
								<List dense>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Military Grade rucksack design"
											secondary="500 count codoura fabric, heavy duty zippers"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Modular accessory kit"
											secondary="Sort everything for your needs"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Water Bottle Holder"
											secondary="Attach your standard neck water bottles right to the bag!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
									<ListItem>
										<ListItemText
											sx={{ textAlign: "center" }}
											primary="Customize with patches!"
											secondary="Velcro patches available in the store!"
											secondaryTypographyProps={{
												sx: {
													color: "#bbb",
													fontStyle: "italic",
												},
											}}
										/>
									</ListItem>
								</List>
							</Grid>
						</Grid>
					)}
				</Container>
			</Box>
			<Box
				name="section3"
				sx={{
					backgroundImage:
						selection === "bowling"
							? "linear-gradient(#333, #000)"
							: "linear-gradient(#00330f, #001c08)",
					color: "#ddd",
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5" sx={{ marginBottom: 2 }}>
						Durable Construction
					</Typography>
					<img
						src={backpack1}
						alt="backpack 2"
						style={{ maxWidth: "100%", maxHeight: 300 }}
					/>
					{selection === "bowling" ? (
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							Designed after the number one travel bag for rough
							terrian, the Nexus Bag takes cues from the military
							rucksack. With 500 count, water-resistant codoura
							fabric, the Nexus Bag can follow you through all
							your bowling adventures!
						</Typography>
					) : (
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							Designed after the number one travel bag for rough
							terrian, the Nexus Bag takes cues from the military
							rucksack. With 500 count, water-resistant codoura
							fabric, the Nexus Bag can follow you through all
							your travel adventures!
						</Typography>
					)}
				</Container>
			</Box>
			<Box
				name="section4"
				sx={{
					backgroundImage:
						selection === "bowling"
							? "linear-gradient(#000, #333)"
							: "linear-gradient(#001c08, #00330f)",
					color: "#ddd",
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5" sx={{ marginBottom: 2 }}>
						Room for Everything
					</Typography>
					<img
						src={backpack3}
						alt="backpack 3"
						style={{ maxWidth: "100%", maxHeight: 300 }}
					/>
					{selection === "bowling" ? (
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							The main compartment has dedicated space for all
							your main equipment. Your laptop, shoes, slides, and
							heels all have dedicated space. The cleaning and
							surface kit has space your 6" surface pads, ball
							cups, and all your cleaners. The accessory kit is
							built with a MOLLE system, allowing you to customize
							the space to fit your needs on the lanes!
						</Typography>
					) : (
						<Typography variant="body1" sx={{ marginTop: 2 }}>
							The main compartment has plenty of space for all
							your things. Your laptop, extra shoes, USB battery
							charger, clothes, notebooks, and much more. The
							accessory kit is built with a MOLLE system, allowing
							you to customize the space to fit your needs with
							whatever you bring with you!
						</Typography>
					)}
				</Container>
			</Box>
			<Box
				name="section5"
				sx={{
					backgroundImage:
						selection === "bowling"
							? "linear-gradient(#333, #000)"
							: "linear-gradient(#00330f, #001c08)",
					color: "#ddd",
					paddingTop: 10,
					paddingBottom: 10,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Container maxWidth="md">
					<Typography variant="h5" sx={{ margin: 2 }}>
						Available at these fine establishments and online!
					</Typography>
					<Grid container>
						<Grid item xs={12} sm={6} md={4}>
							<List
								dense
								component="nav"
								aria-labelledby="list-subheader"
								subheader={
									<ListSubheader
										component="div"
										id="list-subheader"
										sx={{
											background: "none",
											color: "#fff",
											fontSize: 18,
										}}
									>
										Online
									</ListSubheader>
								}
								sx={{ background: "none" }}
							>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="The Bowling Hub"
										secondary="https://thebowlinghub.com"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="Online Proshop"
										secondary="https://onlineproshop.com"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
							</List>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<List
								dense
								component="nav"
								aria-labelledby="list-subheader"
								subheader={
									<ListSubheader
										component="div"
										id="list-subheader"
										sx={{
											background: "none",
											color: "#fff",
											fontSize: 18,
										}}
									>
										Arizona
									</ListSubheader>
								}
								sx={{ background: "none" }}
							>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
							</List>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<List
								dense
								component="nav"
								aria-labelledby="list-subheader"
								subheader={
									<ListSubheader
										component="div"
										id="list-subheader"
										sx={{
											background: "none",
											color: "#fff",
											fontSize: 18,
										}}
									>
										Colorado
									</ListSubheader>
								}
								sx={{ background: "none" }}
							>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
							</List>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<List
								dense
								component="nav"
								aria-labelledby="list-subheader"
								subheader={
									<ListSubheader
										component="div"
										id="list-subheader"
										sx={{
											background: "none",
											color: "#fff",
											fontSize: 18,
										}}
									>
										Nevada
									</ListSubheader>
								}
								sx={{ background: "none" }}
							>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
								<ListItemButton>
									<ListItemText
										sx={{ color: "#fff" }}
										primary="A ProShop"
										secondary="1111 Aroad St., Phoenix"
										secondaryTypographyProps={{
											color: "#ccc",
										}}
									/>
								</ListItemButton>
							</List>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Box
				sx={{
					backgroundImage:
						selection === "bowling"
							? "linear-gradient(#000, #333)"
							: "linear-gradient(#001c08, #00330f)",
					color: "#aaa",
					height: 150,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography>
					Website Design by Scott Hubert @ Weinhaus Creations
				</Typography>
			</Box>
		</div>
	);
};

export default App;
